import { useMemo } from 'react';
import { SignUpFormValidation } from './';

const useValidation = (validation: SignUpFormValidation) => {
  const valid = useMemo(
    () => validation.fName && validation.lName && validation.email && validation.password,
    [validation.fName, validation.lName, validation.email, validation.password]
  );
  return valid;
};

export default useValidation;
