import fieldValidation from '../../utils/fieldValidation';

const validationMethods = {
  fName: fieldValidation.requiredString,
  lName: fieldValidation.requiredString,
  email: fieldValidation.requiredEmail,
  password: (s?: string | null) => fieldValidation.requiredStringWithMinLength(s, 6),
};

export default validationMethods;
