import { BaseAction } from '../../@types/Action';
import validationMethods from './validationMethods';

export interface SignUpFormValidation {
  fName: boolean;
  lName: boolean;
  email: boolean;
  password: boolean;
}
export interface SignUpForm {
  fName: string;
  lName: string;
  email: string;
  password: string;
}

export interface SignUpState {
  value: SignUpForm;
  interact: SignUpFormValidation;
  validation: SignUpFormValidation;
}

export const initialState = (): SignUpState => ({
  value: {
    fName: '',
    lName: '',
    email: '',
    password: '',
  },
  validation: {
    fName: false,
    lName: false,
    email: false,
    password: false,
  },
  interact: {
    fName: false,
    lName: false,
    email: false,
    password: false,
  },
});

export const SET_CHANGE = 'SET_CHANGE';
type SET_CHANGE_TYPE = BaseAction<typeof SET_CHANGE> & {
  name: keyof SignUpForm;
  value: string;
};

export const SET_BLUR = 'REQ_BLSET_BLURUR';
type SET_BLUR_TYPE = BaseAction<typeof SET_BLUR> & {
  name: keyof SignUpForm;
};

export const SET_VALIDATE_EVERYTHING = 'SET_VALIDATE_EVERYTHING';
export type SET_VALIDATE_EVERYTHING_TYPE = BaseAction<typeof SET_VALIDATE_EVERYTHING>;

type SignUpAction = SET_CHANGE_TYPE | SET_BLUR_TYPE | SET_VALIDATE_EVERYTHING_TYPE;

const signUpReducer = (state: SignUpState, action: SignUpAction) => {
  const next = { ...state };
  switch (action.type) {
    case SET_CHANGE:
      next.value[action.name] = action.value;
      next.validation[action.name] = validationMethods[action.name](action.value);
      return next;
    case SET_BLUR:
      next.interact[action.name] = true;
      return next;
    case SET_VALIDATE_EVERYTHING:
      next.validation.fName = validationMethods.fName(next.value.fName);
      next.interact.fName = true;

      next.validation.lName = validationMethods.lName(next.value.lName);
      next.interact.lName = true;

      next.validation.email = validationMethods.email(next.value.email);
      next.interact.email = true;

      next.validation.password = validationMethods.password(next.value.password);
      next.interact.password = true;
      return next;
    default:
      throw new Error(`Unsupported action type`);
  }
};

export default signUpReducer;
