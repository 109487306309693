import React, { SyntheticEvent, useCallback, useEffect, useReducer } from 'react';
import { navigate } from 'gatsby';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Container,
  Grid,
  Box,
  FormHelperText,
} from '@material-ui/core';

import SEO from '../components/seo';
import DefaultLink from '../components/DefaultLink';
import FormAvatar from '../components/account/form/form-avatar';
import FormPaper from '../components/account/form/form-paper';
import ButtonProgressContainer from '../components/account/form/button-progress-container';
import CircularProgressOverlay from '../components/account/form/circular-progress-overlay';
import useAuth from '../State/Auth/useAuth';
import { RequestState } from '../utils/request-state';
import signUpReducer, {
  initialState,
  SignUpForm,
  SET_CHANGE,
  SET_BLUR,
  SET_VALIDATE_EVERYTHING,
} from '../reducers/sign-up';
import useValidation from '../reducers/sign-up/useValidation';

const hasEmailError = ({ errorCode, errorMessage }: RequestState): string | undefined =>
  errorCode === 'auth/email-already-in-use' || errorCode === 'auth/invalid-email'
    ? errorMessage
    : undefined;

const hasPasswordError = ({ errorCode, errorMessage }: RequestState): string | undefined =>
  errorCode === 'auth/weak-password' ? errorMessage : undefined;

const SignUp = () => {
  const {
    firebaseUser,
    signUp,
    requestSignUp,
    requestUpdateDisplayName,
    requestSendEmailVerification,
  } = useAuth();

  const [state, dispatch] = useReducer(signUpReducer, initialState());
  const valid = useValidation(state.validation);

  useEffect(() => {
    if (firebaseUser) {
      navigate('/');
    }
  }, [firebaseUser]);

  const onChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      dispatch({
        type: SET_CHANGE,
        name: e.currentTarget.name as keyof SignUpForm,
        value: e.currentTarget.value,
      }),
    []
  );

  const onBlur = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      dispatch({
        type: SET_BLUR,
        name: e.currentTarget.name as keyof SignUpForm,
      }),
    []
  );

  const onSignUp = useCallback(() => {
    if (valid) {
      signUp(state.value.email, state.value.password, state.value.fName, state.value.lName);
    } else {
      dispatch({
        type: SET_VALIDATE_EVERYTHING,
      });
    }
  }, [state.value, valid]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        onSignUp();
      }
    },
    [onSignUp]
  );

  const loading =
    requestSignUp.loading ||
    requestUpdateDisplayName.loading ||
    requestSendEmailVerification.loading;

  const emailError = hasEmailError(requestSignUp);
  const passwordError = hasPasswordError(requestSignUp);

  return (
    <>
      <SEO title="Sign up" />
      <Container maxWidth="sm">
        <FormPaper>
          <FormAvatar>
            <LockOutlinedIcon />
          </FormAvatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <FormControl
            margin="normal"
            required
            fullWidth
            error={state.interact.fName && !state.validation.fName}
          >
            <InputLabel htmlFor="firstName">First name</InputLabel>
            <Input
              id="firstName"
              name="fName"
              value={state.value.fName}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              autoComplete="firstName"
            />
            {state.interact.fName && !state.validation.fName && (
              <FormHelperText>Must provide a first name.</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            required
            fullWidth
            error={state.interact.lName && !state.validation.lName}
          >
            <InputLabel htmlFor="firstName">Last name</InputLabel>
            <Input
              id="lastName"
              name="lName"
              value={state.value.lName}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              autoComplete="lastName"
            />
            {state.interact.lName && !state.validation.lName && (
              <FormHelperText>Must provide a last name.</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            required
            fullWidth
            error={state.interact.email && !state.validation.email}
          >
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              value={state.value.email}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              autoComplete="email"
            />
            {state.interact.email && !state.validation.email && (
              <FormHelperText>Must provide a valid email.</FormHelperText>
            )}
            {emailError && <FormHelperText>{emailError}</FormHelperText>}
          </FormControl>
          <FormControl
            margin="normal"
            required
            fullWidth
            error={state.interact.password && !state.validation.password}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              value={state.value.password}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              type="password"
              id="password"
            />
            {state.interact.password && !state.validation.password && (
              <FormHelperText>
                Must provide a valid password with more than 5 characters.
              </FormHelperText>
            )}
            {passwordError && <FormHelperText>{passwordError}</FormHelperText>}
          </FormControl>
          <Box mt={2} mb={2} width="100%">
            <ButtonProgressContainer fullWidth>
              <Button
                disabled={loading}
                type="submit"
                onClick={onSignUp}
                fullWidth
                variant="contained"
                color="primary"
                component="button"
              >
                Sign up
              </Button>
              {loading && <CircularProgressOverlay size={30} />}
            </ButtonProgressContainer>
          </Box>
          <Grid container>
            <Grid item xs>
              <DefaultLink href="/forgot-password" variant="body2">
                Forgot password?
              </DefaultLink>
            </Grid>
            <Grid item>
              <DefaultLink href="/login" variant="body2">
                Already have an account? Sign in
              </DefaultLink>
            </Grid>
          </Grid>
        </FormPaper>
      </Container>
    </>
  );
};

export default SignUp;
